/* You can add global styles to this file, and also import other style files */

@import "@angular/material/theming";
@import "~bootstrap/dist/css/bootstrap.css";


body {
    width: 100%;
    height: calc(100vh - 0px);
}

.primaryTextColor {
    color: #0267c1;
    font-weight: 600;
}
.font600 {
    font-weight: 600 !important;
}
.errorGradient {
    font-weight: bold;
    background-image: linear-gradient(to right, #ff4e50, #f9d423);
    -webkit-text-fill-color: transparent;
    font-family: 'interMedium';
}
.m-auto {
    margin: auto !important;
}

.success-snackbar,
.error-snackbar,
.warning-snackbar {
    background-color: #292929 !important;
    color: whitesmoke !important;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        width: 250px;
        height: 90px;
        border-radius: 50%;
        background-color: #01dc6a;
        position: absolute;
        left: -40px;
        z-index: 1;
        filter: blur(56px);
    }
}

.success-snackbar {
    &::before {
        background-color: #01dc6a;
    }

    .mat-icon {
        color: #01dc6a;
    }
}

.error-snackbar {
    &::before {
        background-color: #fe3451;
    }

    .mat-icon {
        color: #fe3451;
    }
}

.warning-snackbar {
    &::before {
        background-color: #ffcc00;
    }

    .mat-icon {
        color: #ffcc00;
    }
}

.noteText {
    color: #0267c1;
    font-weight: 600;
}

.cdk-overlay-container {
    z-index: 10000 !important;
}

.w-20 {
    width: 20% !important;
}
.w-30 {
    width: 30% !important;
}
.height100vh {
    height: 100vh !important;
}
.font500 {
    font-weight: 500 !important;
}
.font300 {
    font-weight: 300 !important;
}
.mr-2 {
    margin-right: 10px !important;
}
.mt-2 {
    margin-top: 10px !important;
}
.ml-auto {
    margin-left: auto !important;
}
.font14 {
    font-size: 14px !important;
}
.font16 {
    font-size: 16px !important;
}
.font18 {
    font-size: 18px !important;
}
.font20 {
    font-size: 20px !important;
}

//component library related styles
/* You can add global styles to this file, and also import other style files */

.cursor-pointer {
    cursor: pointer;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .mat-mdc-text-field-wrapper{
    height: 36px !important;
  }
  .mat-mdc-form-field-flex{
    height: 100% !important;
    align-items: center !important;
  }
  .mat-mdc-select-trigger{
    height: 36px !important;
  }
  .mat-mdc-form-field-infix{
    padding: 0px !important;
  }

  .heading-large{
    font-size: calc(36px* var(--head-fontSize, 1));
    line-height: calc(42px* var(--head-fontHeight, 1));
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  .body-large{
    font-size: calc(20px* var(--body-fontSize, 1));
    line-height: calc(30px* var(--body-fontHeight, 1));
    font-family: var(--bs-body-font-family);
    font-weight: var(--bs-body-font-weight);
  }
  .body-desc{
    font-size: calc(16px* var(--body-fontSize, 1));
    font-weight: 400;
    font-family: var(--bs-body-fontFamily);
    font-weight: var(--bs-body-font-weight);
  }
  button{
    font-size: 1.25rem;
    width: 100% ;
  }
  .heading-medium {
    font-size: calc(30px* var(--head-fontSize, 1));
    line-height: calc(38px* var(--head-fontHeight, 1));
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  .heading-small{
    font-size: calc(20px* var(--head-fontSize, 1));
    line-height: calc(38px* var(--head-fontHeight, 1));
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  // h1,h2,h3,h4,h5,h6
  h1,h2,h3, h4,h5,h6 {
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  p, span {
    font-family: var(--bs-body-font-family);
    font-weight: var(--bs-body-font-weight);
  }
  @media only screen and (min-width : 768px){
    .heading-large{
      font-size: calc(46px* var(--head-fontSize, 1));
      line-height: calc(54px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .heading-medium {
      font-size: calc(30px* var(--head-fontSize, 1));
      line-height: calc(38px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .body-large{
      font-size: calc(16px* var(--body-fontSize, 1));
      line-height: calc(30px* var(--body-fontHeight, 1));
      font-family: var(--bs-body-font-family)!important;
      font-weight: var(--bs-body-font-weight);
    }
  }

  @media only screen and (min-width : 1280px){
    .heading-large{
      font-size: calc(48px* var(--head-fontSize, 1));
      line-height: calc(65px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .body-large{
      font-size: calc(16px* var(--body-fontSize, 1));
      line-height: calc(30px* var(--body-fontHeight, 1));
      font-family: var(--bs-body-font-family);
      font-weight: var(--bs-body-font-weight);
    }
    .heading-medium {
      font-size: calc(30px* var(--head-fontSize, 1));
      line-height: calc(38px* var(--head-fontHeight, 1));
      font-family: var(--bs-head-font-family);
      font-weight: var(--bs-head-font-weight);
    }
    .body-desc{
      font-size: calc(18px* var(--body-fontSize, 1));
      font-weight: 400;
      font-family: var(--bs-body-fontFamily);
      font-weight: var(--bs-body-font-weight);
    }
  }

  .field{
    .mdc-evolution-chip-set {
      display: flex;
      width: 100% !important;
  }
  }

.view-order-timeline {
  .p-timeline-left .p-timeline-event-opposite {
    display: none;
  }
}
.input_card{
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border:2px solid#D0D5DD !important;
    height: 40px !important;
  }
}
.input_card{
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-left: 0px !important;
  }
}
.input_card{
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading{
    border-right: 0px !important;
  }
}
.full-width_color {
  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      margin-top: -3px;
      .mat-mdc-select-value-text {
        color: #000000 !important;
        font-weight: bolder !important;
        margin-right: 9px !important;
      }
    }
  }
}
.quill-editor{
  .ql-toolbar.ql-snow {
    border: 2px solid #D0D5DD;
    box-sizing: border-box;
    padding: 8px;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
  }
  .ql-container.ql-snow {
    border: 1px solid #ccc;
    border: 2px solid #D0D5DD;
    box-sizing: border-box;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    font-size: 16px;
  }
}


.swal2-container {
  z-index: 10000001 !important;
}
.list-pages{
  .cdk-drag-placeholder{
    opacity: 100;
  }
}

.cdk-overlay-pane{
  // position: relative;
  // top: 16%;
  left :75%;
}
.add-category{
  .mat-pseudo-checkbox{
    display: none;
  }
}
.map-section{
  .mapboxgl-control-container {
    z-index: 0 !important;
}
}
.timepicker-overlay {
  position: relative;
  z-index: 200000 !important;
}
.delivery-type{
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
}
}
.day-dropdown{
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}


.afterLoadImg:hover {
  -webkit-animation: scale-up-center 0.2s linear both;
    animation: scale-up-center 0.2s linear both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
  }
}

.datetime{
  .mat-mdc-form-field{
    display: none !important;
  }
}
.table-section{
  .mat-mdc-table .mdc-data-table__row {
    height: 52px;
    border-bottom: 1px solid lightgray;
  }
}

@media only screen and (min-width: 300px) and (max-width: 1000px){
  .input_card{
    .mat-mdc-text-field-wrapper {
      width: 250px !important;
    }
  }
}
.content-side {
  h1, h2, h3 {
    font-family: var(--bs-head-font-family);
    font-weight: var(--bs-head-font-weight);
  }
  h4, h5, p {
    font-family: var(--bs-body-font-family);
    font-weight: var(--bs-body-font-weight);
  }
}
.modal-backdrop{
  z-index: 10;
}

.modal-backdrop
{
  display: none;
}
.admission {
  .mdc-text-field--outlined .mdc-notched-outline  {
    border: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing  {
    border: none;
  }
}
